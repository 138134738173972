<template>
  <!-- 参加修改活动弹框 -->
  <el-dialog
    :title="operateType == 'add' ? '参加活动' : operateType == 'edit' ? '修改报名信息' : operateType == 'show' ? '查看规格' : '增加库存'"
    :visible.sync="joinDialogVisible"
    :before-close="closeHandle"
    width="70%"
  >
    <p style="color: #999; marginBottom: 10px">
      该页面中的商品为符合报名条件的商品
    </p>
    <el-table
      v-loading="skuLoading"
      :data="skuTableData"
      border
      style="width: 100%"
      element-loading-text="拼命加载中"
    >
      <el-table-column
        label="商品信息"
        min-width="210"
      >
        <template slot-scope="scope">
          <div class="goods_message">
            <div class="left">
              <img :src="scope.row.imageUrls" />
            </div>
            <div class="right">
              <div class="title">
                {{ scope.row.goodsName }}
              </div>
              <div class="id">
                库存: {{ scope.row.stockQuantity }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="skuName"
        label="规格"
        min-width="110"
      />
      <el-table-column
        prop="sellPrice"
        label="原价(元)"
        min-width="110"
      />
      <el-table-column
        prop="stockQuantity"
        label="库存"
        min-width="110"
      />
      <el-table-column
        label="活动价(元)"
        width="200"
        v-if="!isCsfr"
      >
        <template slot-scope="scope">
          <el-input
            :class="{
              'error-bolder': !getDiscountPass(scope?.row?.promPrice, scope?.row?.promPriceRequirement) && scope?.row.alreadyInputPromPrice
            }"
            :value="scope.row.promPrice"
            @input="(e)=>{ 
              scope.row.promPrice = e
              scope.row.alreadyInputPromPrice = true
            }"
            :disabled="operateType == 'increase'"
            size="mini"
            :max="scope.row.sellPrice"
          ></el-input>
          <div v-if="!getDiscountPass(scope?.row?.promPrice, scope?.row?.promPriceRequirement) && scope?.row.alreadyInputPromPrice" class="pass-tip">
            折扣须不低于 {{ scope?.row?.promPriceDiscountRequirement * 100 / 10}}折
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="活动库存"
        width="200"
        v-if="!isCsfr"
      >
        <template slot-scope="scope">
          <el-input
            :class="{
              'error-bolder': !getInventoryPass(scope?.row?.promStock, scope?.row?.promStockRequirement) && scope?.row.alreadyInputPromStock
            }"
            :value="scope.row.promStock"
            @input="(e)=>{ 
              scope.row.promStock = e
              scope.row.alreadyInputPromStock = true
            }"
            :disabled="operateType == 'increase'"
            size="mini"
            :max="scope.row.originalStockQuantity"
          ></el-input>
          <div v-if="!getInventoryPass(scope?.row?.promStock, scope?.row?.promStockRequirement) && scope?.row.alreadyInputPromStock" class="pass-tip">
            库存须不低于 {{ scope?.row?.promStockRequirement }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="operateType == 'increase' && !isCsfr"
        label="增加库存"
        min-width="140"
      >
        <template slot-scope="scope">
          <!-- <el-input
            v-model="scope.row.addPromStock"
            size="mini"
          ></el-input> -->
          <el-input-number v-model="scope.row.addPromStock" :min="0" :max="999999" label="增加库存数" size="mini" @blur="scope.row.addPromStock = scope.row.addPromStock >= 0 ? scope.row.addPromStock : 0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!isCsfr"
        label="预警库存"
        min-width="140"
      >
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.promStockWarning" :min="0" :max="999999" label="预警库存" size="mini"></el-input-number>
        </template>
      </el-table-column>
    </el-table>
    <span
      slot="footer"
      class="dialog-footer"
      v-if="operateType !== 'show'"
    >
      <el-button
        type="primary"
        @click="onSureApply"
        size="small"
      >确认{{ operateType == 'add' ? '报名' : '修改报名' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ChangeGoodsDialog',
  props: {
    joinDialogVisible: Boolean,
    operateType: String,
    operateRowData: Object,
    id: Number,
    promId: String,
    tradePromInfo: Object
  },
  data() {
    return {
      skuTableData: [], //
      skuLoading: false
    }
  },
  computed: {
    isCsfr() {
      return this.$route.query.activityType === '6'
    }
  },
  mounted() {
    this.getSkuTable()
  },
  methods: {
    getDiscountPass(data, discountsValue) {
      if (discountsValue === undefined || discountsValue === null) {
        return true
      }
      return parseFloat(data) <= parseFloat(discountsValue)
    },
    getInventoryPass(data, promStock) {
      if (promStock === undefined || promStock === null) {
        return true
      }
      return data >= promStock
    },
    getTableDataPass() {
      let inventoryPass = true;
      let discountPass = true;
      if (this.isCsfr) {
        return true
      }

      for (const item of this.skuTableData) {
        if (!this.getInventoryPass(item.promStock, item.promStockRequirement)) {
          inventoryPass = false
          break
        }
        if (!this.getDiscountPass(item.promPrice,  item.promPriceRequirement)) {
          discountPass = false
          break
        }
      }

      return inventoryPass && discountPass
    },
    closeHandle() {
      this.$emit('update:joinDialogVisible', false)
    },
    // 获取多规格列表
    getSkuTable() {
      this.skuLoading = true
      let url;
      if (this.operateType === 'add' || this.operateType === 'readd') {
        url = this.$api.tradeProm.goodsskulist
      } else {
        url = this.$api.tradeProm.promgoodsskulist
      }
      let params = (this.operateType === 'add' || this.operateType === 'readd') ? {
        goodsId: this.operateRowData.goodsId,
        promId: this.promId
      } : {
        promGoodsId: this.operateRowData.id,
        promId: this.promId

      }

      this.$axios(url, {
        params
      }).then(res => {
        if (res.code === 0) {
          this.skuTableData = res.data
          this.skuLoading = false
        }
      })
    },

    // 确认&修改报名
    onSureApply() {
      this.$confirm('是否确认操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        
        let url = this.operateType == 'add' ? this.$api.tradeProm.sure_apply : this.operateType == 'readd' ? this.$api.tradeProm.re_sure_apply : this.operateType == 'edit' ? this.$api.tradeProm.change_apply : this.$api.tradeProm.add_stock
        let params = this.operateType == 'add' ? {
          goodsId: this.operateRowData.goodsId,
          promId: this.promId,
          storeId: this.operateRowData.storeId,
          suppliersId: this.operateRowData.suppliersId,
          tradePromGoodsSkuDtoList: this.skuTableData
        } : {
          goodsId: this.operateRowData.goodsId,
          promId: this.promId,
          tradePromGoodsSkuDtoList: this.skuTableData,
          promGoodsId: this.operateRowData.id
        };
        this.$axios.post(url, params).then(res => {
          if (res.code === 0) {
            this.$message.success(`${this.operateType == 'add' ? '报名成功' : this.operateType == 'readd' ? '重新报名成功' : this.operateType == 'edit' ? '修改报名成功' : '增加库存成功'}`)
            this.closeHandle()
            // if(this.operateType == 'add') {
            //   this.activeName = '0'
            // }
            this.$emit('onupdatetable')
            // this.getList(this.activeName, 1)
          }
        }).catch(error => {
          console.log(error)
        })

      }).catch(() => {        
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.pass-tip {
  position: absolute;
  bottom: 20px;
}
.error-bolder {
  :deep {
    .el-input__inner {
      border-color: red;
    }
  }
}
</style>