var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.operateType == "add"
            ? "参加活动"
            : _vm.operateType == "edit"
            ? "修改报名信息"
            : _vm.operateType == "show"
            ? "查看规格"
            : "增加库存",
        visible: _vm.joinDialogVisible,
        "before-close": _vm.closeHandle,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.joinDialogVisible = $event
        },
      },
    },
    [
      _c("p", { staticStyle: { color: "#999", marginBottom: "10px" } }, [
        _vm._v(" 该页面中的商品为符合报名条件的商品 "),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.skuLoading,
              expression: "skuLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.skuTableData,
            border: "",
            "element-loading-text": "拼命加载中",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品信息", "min-width": "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "goods_message" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("img", { attrs: { src: scope.row.imageUrls } }),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(" " + _vm._s(scope.row.goodsName) + " "),
                        ]),
                        _c("div", { staticClass: "id" }, [
                          _vm._v(
                            " 库存: " + _vm._s(scope.row.stockQuantity) + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "skuName", label: "规格", "min-width": "110" },
          }),
          _c("el-table-column", {
            attrs: { prop: "sellPrice", label: "原价(元)", "min-width": "110" },
          }),
          _c("el-table-column", {
            attrs: { prop: "stockQuantity", label: "库存", "min-width": "110" },
          }),
          !_vm.isCsfr
            ? _c("el-table-column", {
                attrs: { label: "活动价(元)", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            class: {
                              "error-bolder":
                                !_vm.getDiscountPass(
                                  scope?.row?.promPrice,
                                  scope?.row?.promPriceRequirement
                                ) && scope?.row.alreadyInputPromPrice,
                            },
                            attrs: {
                              value: scope.row.promPrice,
                              disabled: _vm.operateType == "increase",
                              size: "mini",
                              max: scope.row.sellPrice,
                            },
                            on: {
                              input: (e) => {
                                scope.row.promPrice = e
                                scope.row.alreadyInputPromPrice = true
                              },
                            },
                          }),
                          !_vm.getDiscountPass(
                            scope?.row?.promPrice,
                            scope?.row?.promPriceRequirement
                          ) && scope?.row.alreadyInputPromPrice
                            ? _c("div", { staticClass: "pass-tip" }, [
                                _vm._v(
                                  " 折扣须不低于 " +
                                    _vm._s(
                                      (scope?.row
                                        ?.promPriceDiscountRequirement *
                                        100) /
                                        10
                                    ) +
                                    "折 "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2589786818
                ),
              })
            : _vm._e(),
          !_vm.isCsfr
            ? _c("el-table-column", {
                attrs: { label: "活动库存", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            class: {
                              "error-bolder":
                                !_vm.getInventoryPass(
                                  scope?.row?.promStock,
                                  scope?.row?.promStockRequirement
                                ) && scope?.row.alreadyInputPromStock,
                            },
                            attrs: {
                              value: scope.row.promStock,
                              disabled: _vm.operateType == "increase",
                              size: "mini",
                              max: scope.row.originalStockQuantity,
                            },
                            on: {
                              input: (e) => {
                                scope.row.promStock = e
                                scope.row.alreadyInputPromStock = true
                              },
                            },
                          }),
                          !_vm.getInventoryPass(
                            scope?.row?.promStock,
                            scope?.row?.promStockRequirement
                          ) && scope?.row.alreadyInputPromStock
                            ? _c("div", { staticClass: "pass-tip" }, [
                                _vm._v(
                                  " 库存须不低于 " +
                                    _vm._s(scope?.row?.promStockRequirement) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1234626875
                ),
              })
            : _vm._e(),
          _vm.operateType == "increase" && !_vm.isCsfr
            ? _c("el-table-column", {
                attrs: { label: "增加库存", "min-width": "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max: 999999,
                              label: "增加库存数",
                              size: "mini",
                            },
                            on: {
                              blur: function ($event) {
                                scope.row.addPromStock =
                                  scope.row.addPromStock >= 0
                                    ? scope.row.addPromStock
                                    : 0
                              },
                            },
                            model: {
                              value: scope.row.addPromStock,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "addPromStock", $$v)
                              },
                              expression: "scope.row.addPromStock",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3466402532
                ),
              })
            : _vm._e(),
          !_vm.isCsfr
            ? _c("el-table-column", {
                attrs: { label: "预警库存", "min-width": "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max: 999999,
                              label: "预警库存",
                              size: "mini",
                            },
                            model: {
                              value: scope.row.promStockWarning,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "promStockWarning", $$v)
                              },
                              expression: "scope.row.promStockWarning",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  974810550
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.operateType !== "show"
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSureApply },
                },
                [
                  _vm._v(
                    "确认" +
                      _vm._s(_vm.operateType == "add" ? "报名" : "修改报名")
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }