var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "参加活动（民宿商品）",
        visible: _vm.show,
        "before-close": _vm.onClose,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        opened: _vm.onOpened,
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex", justify: "space-between" } },
        [
          _c(
            "el-col",
            { attrs: { span: 9 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm.row.imageUrls
                      ? _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src: _vm.row.imageUrls.split(",")[0],
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                      _vm._v(_vm._s(_vm.row.goodsName)),
                    ]),
                    _c("div", [_vm._v("ID：" + _vm._s(_vm.row.goodsId))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c("h1", { staticStyle: { "margin-bottom": "20px" } }, [
                _vm._v("活动价格"),
              ]),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { gutter: 10 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(" 改价方式： "),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("批量减价"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("批量打折"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(" 统一调价： "),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _vm.type == 2
                        ? _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", size: "small" },
                              model: {
                                value: _vm.discount,
                                callback: function ($$v) {
                                  _vm.discount = $$v
                                },
                                expression: "discount",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.type == 1
                        ? _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入内容",
                                type: "number",
                                size: "small",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.money = _vm.money
                                    .replace(/[^\d]/g, "")
                                    .replace(/^0\d*$/g, "")
                                },
                              },
                              model: {
                                value: _vm.money,
                                callback: function ($$v) {
                                  _vm.money =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "money",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("减"),
                              ]),
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onClose } },
            [_vm._v("取 消")]
          ),
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSubmit },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.activeType === "add" ? "确认报名" : "确认重新报名"
                    )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }