<template>
  <el-dialog
    title="参加活动（民宿商品）"
    :visible.sync="show"
    @opened="onOpened"
    :before-close="onClose"
    width="700px">
    <el-row :gutter="20" type="flex" justify="space-between">
      <el-col :span="9">
        <el-row :gutter="10">
          <el-col :span="12">
            <img v-if="row.imageUrls" style="width: 100%;" :src="row.imageUrls.split(',')[0]" alt="">
          </el-col>
          <el-col :span="12">
            <div style="margin-bottom: 10px">{{row.goodsName}}</div>
            <div>ID：{{row.goodsId}}</div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="14">
        <h1 style="margin-bottom: 20px">活动价格</h1>
        <el-row :gutter="10" style="margin-bottom: 10px">
          <el-col :span="6">
            改价方式：
          </el-col>
          <el-col :span="18">
            <el-radio-group v-model="type">
              <el-radio :label="1">批量减价</el-radio>
              <el-radio :label="2">批量打折</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            统一调价：
          </el-col>
          <el-col :span="18">
            <el-select v-if="type == 2" v-model="discount" placeholder="请选择" size="small">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>

            <el-input 
              v-if="type == 1" 
              placeholder="请输入内容" 
              type="number"
              v-model.trim="money" 
              size="small" 
              @input=" money = money.replace(/[^\d]/g, '').replace(/^0\d*$/g, '')">

              <template slot="prepend">减</template>
              <template slot="append">元</template>

            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose" size="small">取 消</el-button>
      <el-button v-if="!disabled" type="primary" @click="onSubmit" size="small">{{activeType === 'add' ? '确认报名' : '确认重新报名'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: ()=>{}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    promId: {
      typr: String,
      default: '0'
    },
    auditStatus: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeType: 'add',
      type: 1,
      discount: '0.90',
      money: '',
      skuData: {},
      options: [{
        id: '0.90',
        label: '9折'
      },{
        id: '0.80',
        label: '8折'
      },{
        id: '0.70',
        label: '7折'
      },{
        id: '0.60',
        label: '6折'
      },{
        id: '0.50',
        label: '5折'
      },]
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    //打开弹窗
    onOpened() {
      if(this.row.id) {
        this.activeType = 'edit';

        this.$axios.get(this.$api.tradeProm.add_hotel_sku,{
          params: {
            goodsId: this.row.goodsId,
            promId: this.promId,
          }
        }).then((res)=>{
          if(res.code === 0) {
            this.skuData = res.data;

            this.type = this.skuData.discountsType;
            if(this.type == 1) {
              this.money = this.skuData.discountsValue;
            }else if(this.type == 2) {
              this.discount = this.skuData.discountsValue;
            }
          }
        })
      }else {
        this.activeType = 'add';
      }
    },
    onClose() {
      this.$emit('update:show', false)
    },
    onSubmit() {
      if(this.type == 1 && !this.money) {
        this.$message({
          type: 'warning',
          message: '请设置减价'
        })
        return
      }
      this.$confirm(this.activeType === 'add' ? '是否确认报名活动？' : '是否确认重新报名？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = this.activeType === 'add' ? this.$api.tradeProm.add_hotel : this.$api.tradeProm.re_add_hotel
        this.$axios.post(url,{
          discountsType: this.type,
          discountsValue: this.type == 1 ? this.money : this.discount,
          goodsId: this.row.goodsId,
          promId: this.promId,
          storeId: this.row.storeId,
          suppliersId: this.row.suppliersId,
          promGoodsSkuId: this.activeType === 'add' ? undefined : this.skuData.id,
          promGoodsId: this.activeType === 'add' ? undefined : this.skuData.promGoodsId,
          auditStatus: this.auditStatus,//0-待审核  1-已审核  2-已驳回  3-已取消。默认传0，运营后台传1
        }).then((res)=>{
          if(res.code === 0) {
            this.$message.success('操作成功！')
            this.onClose();
            this.$emit('getList')
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">

</style>